import { graphql } from 'gatsby'
import React, { useState } from 'react'
import FigureFilter from '../components/FigureFilter'
import Layout from '../components/layout'
import ListFigureItem from '../components/ListFigureItem'
import SEO from '../components/seo'

export const query = graphql`
query {
    allSanityFigure(filter: {isSold: {eq: false}}) {
        nodes {
            id
            isComplete
            name
            image {
                crop {
                    _key
                    _type
                    top
                    bottom
                    left
                    right
                  }
                  hotspot {
                    _key
                    _type
                    x
                    y
                    height
                    width
                  }
                    asset {
                        _id
                }
            }
            accessories
            price
            version
            year
            slug {
                current
            }
        }
    }

    gijoeio {
        publicCollections {
          collection(alias: "havard", type: "for-sale") {
            figures {
              brokenParts
              condition {
                name
                id
              }
              figure {
                id
                imageUrlRaw
                name
                slug
                tags
                type
                version
                year
              }
              id
              images {
                id
                location
              }
              joints
              missingaccessory {
                name
                id
              }
              note
              oring
              ownedaccessory {
                id
                name
              }
              paint
              price
            }
          }
        }
      }
    
}
`

const ForSalePage = props => {
    const {data} = props
    let figures = data.allSanityFigure.nodes
    const appFigures = data.gijoeio?.publicCollections?.collection?.figures

    let convertedAppFigures = appFigures.map(x => ({
        accessories: x.ownedaccessory.map(accessory => accessory.name),
        id: x.id,
        image: x.images.length === 0 ? `https://cms-aks.gijoe.io${x.figure.imageUrlRaw}` : `https://gijoe-io.imgix.net/${x.images[0]?.location}?w=400`,
        isComplete: x.missingaccessory.length === 0,
        name: x.figure.name,
        price: x.price,
        slug: {
            current: `${x.figure.slug}-v${x.figure.version}-${x.figure.year}`
        },
        version: x.figure.version,
        year: x.figure.year,
        type: "gijoeio"

    }))

    figures = figures.concat(convertedAppFigures)

    console.log(convertedAppFigures)

    console.log(appFigures)
    console.log(figures)

    const [filterState, setFilterState] = useState({
        complete: true,
        incomplete: true,
        loose: true
    })

    let filteredFigures = []

    if (filterState.complete === true) {
        filteredFigures.push(...figures.filter(x => x.isComplete))
    }

    if (filterState.incomplete === true) {
        filteredFigures.push(...figures.filter(x => (!x.isComplete && x.accessories.length > 0)))
    }

    if (filterState.loose === true) {
        let filter = figures.filter(x => (!x.isComplete && x.accessories.length === 0))
        filteredFigures.push(...filter)
    }
    
    
    return (
        <Layout>
            <SEO title="Til salgs" description="Actionfigurer fra barndommen til salgs"/>
            <div className="container">
              <div className="mx-auto md:w-3/5  mt-10">
                      <h1 className="text-4xl text-bold mt-5 text-center">Til salgs</h1>
                      <p className="text-center mt-10">Skaff deg en figur, enten om du er ivrig samler eller bare ønsker et kult minne!</p>
              </div>
              <FigureFilter filterState={filterState} setFilterState={setFilterState}/>
              <div className="mt-11 md:mt-16">
                  <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
                      {filteredFigures.map(figure => (
                                  <ListFigureItem figure={figure} key={figure.id}/>                                                          
                          ))}
                  </div>
              </div>
            </div>

        </Layout>
    )
}

export default ForSalePage