import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import React from 'react'


const ListFigureItem = ({figure}) => {
    const sanityConfig = {projectId: 'a3n0vinp', dataset: 'production'}
    const imageData = getGatsbyImageData(figure.image.asset, {maxWidth: 350}, sanityConfig)
    return(
      <div className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden mb-16" key={figure.id}>
            <Link to={`/${figure.slug.current}`}>
                <div className="overflow-hidden">
                    {figure.type !== "gijoeio" 
                    ? <GatsbyImage alt="bilde av figur" image={imageData} className="transition duration-500 ease-in-out md:transform hover:scale-150"/>
                    : <img alt="bilde av figur" width={384} src={figure.image} className="transition duration-500 ease-in-out md:transform hover:scale-150" />
                    }
                </div>
            </Link>
            <div className="flex">                                   
                <div className="flex items-start justify-start">
                    <div className="px-5 py-3">
                        <h3 className="text-gray-700 uppercase"><Link to={`/${figure.slug.current}`}>{figure.name} v{figure.version} ({figure.year})</Link></h3>
                        <span className="text-gray-500 mt-2">{figure.price} kr</span>
                    </div>
                </div>
                <div className="flex items-end justify-end flex-grow">
                </div>
            </div>
        </div>
    )

}

export default ListFigureItem