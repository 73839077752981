import React from 'react'

const FigureFilter = ({filterState, setFilterState}) => {
    return (
        <div className="flex justify-center mt-10 text-xs md:text-base">
            <div className="px-2 md:px-5">
                <input className="mr-1" defaultChecked={filterState.complete} id="complete" name="complete" type="checkbox" onChange={(e) => {
                    setFilterState({...filterState, complete: e.target.checked})
                    } } />
                <label htmlFor="complete">Komplette</label>
            </div>
            <div className="px-2 md:px-5">
                <input className="mr-1" defaultChecked={filterState.incomplete} id="incomplete" name="incomplete" type="checkbox" onChange={(e) => setFilterState({...filterState, incomplete: e.target.checked})}/>
                <label htmlFor="incomplete">Delvis komplette</label>
            </div>
            <div className="px-2 md:px-5">
                <input className="mr-1" defaultChecked={filterState.loose} id="loose" name="loose" type="checkbox" onChange={(e) => setFilterState({...filterState, loose: e.target.checked})}/>
                <label htmlFor="loose">Løse</label>
            </div>
        </div>
    )
}

export default FigureFilter